import React, { useRef, useState } from "react";
import bar1 from "../assets/bar1.png";
import bar2 from "../assets/bar2.png";
import beers from "../assets/beers.png";
import delirium from "../assets/delirium1.png";
import { motion } from "framer-motion";

const Bar = () => {
  const [zoom, setZoom] = useState(null);
  const [recto, setRecto] = useState(true);
  const constraintRef = useRef(null);
  const logoChain = [].concat(...Array(15).fill([beers, delirium]));
  return (
    <div className="h-fit pt-40 z-0 pb-20 w-screen flex flex-col items-center text-5xl font-serif px-4 lg:px-40  bg-b text-w">
      <h1 className="text-5xl lg:7xl text-w z-10 font-serif mb-10">
        Carte du bar
      </h1>
      <button
        className="border capitalize bg-b hover:bg-c3 z-10 hover:text-b mb-4 border-c3 px-2 py-1 rounded-md backdrop-blur-sm font-serif text-xl text-center lg:text-3xl text-c3 "
        onClick={() => setRecto(!recto)}
      >
        {recto ? "verso" : "recto"}
      </button>
      <div className="fixed inset-0 grid opacity-50 grid-cols-5 z-0">
        {logoChain.map((logo, i) => (
          <motion.img
            initial={{ opacity: 0, scale: 0, rotate: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              rotate: 360,
              transition: {
                delay: i * 0.1,
                type: "spring",
              },
            }}
            key={i}
            className={
              "w-20 h-20 lg:w-32 lg:h-32 self-center justify-self-center z-0"
            }
            src={logo}
            alt="logo de bière"
          />
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.5,
            type: "spring",
          },
        }}
        onClick={(e) => e.stopPropagation()}
        ref={constraintRef}
        className="relative w-full grid place-content-center h-64 lg:h-fit overflow-hidden z-10"
      >
        <motion.img
          className={
            zoom === "bar1" ? "w-[900px] h-[600px] max-w-none" : "w-full h-full"
          }
          src={recto ? bar2 : bar1}
          drag={zoom === "bar1" ? true : false}
          dragConstraints={constraintRef}
          alt="Recto du menu du bar"
        />
        {zoom !== "bar1" && (
          <button
            onClick={() => setZoom("bar1")}
            className="absolute lg:hidden border border-c3 px-2 py-1 rounded-md backdrop-blur-sm font-serif text-xl text-center lg:text-3xl text-c3 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-20 transition-all duration-200"
          >
            Agrandir la carte
          </button>
        )}
      </motion.div>
    </div>
  );
};

export default Bar;
