import React from "react";

const Legal = () => {
  return (
    <div className="text-w pt-52 pb-20 px-40">
      Mentions Légales
      <br />
      <br /> 1. Éditeur du Site
      <br />
      <br /> <br />
      <br /> Le site www.westparc.fr est édité par la société West Parc, SAS
      West Parc, immatriculée au Registre du Commerce et des Sociétés de Dinard
      sous le numéro 79252632900026, dont le siège social est situé au : <br />
      <br /> 1 Rue des Villes Billy <br /> 35780 La Richardais <br /> Bretagne,
      France.
      <br />
      <br /> <br />
      <br /> Directeur de la publication : BRIZARD Ludovic
      <br />
      <br /> <br />
      <br /> Contact :<br />
      <br /> <br />
      <br /> Téléphone : 02 23 16 97 68
      <br />
      <br /> E-mail : westbowling.dinard@gmail.com
      <br />
      <br />
      <br />
      <br /> 2. Hébergement du Site
      <br />
      <br /> <br />
      <br /> L'hébergement du site www.westparc.fr est assuré par la société{" "}
      <br />
      Vercel, située au 440 N Barranca Ave #4133, Covina, CA 91723, États-Unis.{" "}
      <br />
      email: privacy@vercel.com
      <br />
      <br /> <br />
      <br /> 3. Accès au Site
      <br />
      <br /> <br />
      <br /> L'accès au site est possible 24 heures sur 24, 7 jours sur 7, sauf
      en cas de force majeure, problèmes informatiques, problèmes liés à la
      structure des réseaux de communication ou problèmes techniques.
      <br />
      <br /> <br />
      <br /> 4. Collecte des Données
      <br />
      <br /> <br />
      <br /> Les informations personnelles pouvant être recueillies sur le site
      sont principalement utilisées par l'éditeur pour la gestion des relations
      avec vous. Les informations personnelles collectées sont traitées
      conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 relative
      à l'informatique, aux fichiers et aux libertés.
      <br />
      <br /> <br />
      <br /> 5. Cookies
      <br />
      <br /> <br />
      <br /> Le site peut collecter automatiquement des informations standards.
      Toutes les informations collectées indirectement ne seront utilisées que
      pour suivre le volume, le type et la configuration du trafic utilisant ce
      site, pour en développer la conception et l'agencement et à d'autres fins
      administratives et de planification.
      <br />
      <br /> <br />
      <br /> 6. Propriété Intellectuelle
      <br />
      <br /> <br />
      <br /> La structure générale du site www.westparc.fr, ainsi que les
      textes, graphiques, images, sons et vidéos la composant, sont la propriété
      de l'éditeur ou de ses partenaires. Toute représentation et/ou
      reproduction et/ou exploitation partielle ou totale des contenus et
      services proposés par le site www.westparc.fr, par quelque procédé que ce
      soit, sans l'autorisation préalable et par écrit de West Parc et/ou de ses
      partenaires, est strictement interdite et serait susceptible de constituer
      une contrefaçon au sens des articles L.335-2 et suivants du Code de la
      propriété intellectuelle.
    </div>
  );
};

export default Legal;
