import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Bowling from "./pages/Bowling";
import Laser from "./pages/Laser";
import Arcade from "./pages/Arcade";
import Trampo from "./pages/Trampo";
import Bar from "./pages/Bar";
import Tarifs from "./pages/Tarifs";
import Events from "./pages/Events";
import Bday from "./pages/Bday";
import Groups from "./pages/Groups";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Privacy from "./pages/Privacy";
import Legal from "./pages/Legal";
import logo from "./assets/logoNoBg.png";
import { motion, AnimatePresence } from "framer-motion";
import { request } from "graphql-request";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {
  const [events, setEvents] = useState(null);
  const [horaire, setHoraire] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const { pathname } = useLocation();
  const w = window.innerWidth < 992;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const fetchProducts = async () => {
      const { events, horaire } = await request(
        `${process.env.REACT_APP_HYGRAPH_API_URL}`,
        `
        {
          horaire(where: {id: "clnwwleejgk3f0aut6dt4yuzb"}) {
            horaire
          }
          events(orderBy: dateTi_ASC) {
            id
            dateT
            dateTi
            description
            title
            src {
              url
            }
          }
        }
    `
      );
      setHoraire(horaire);
      setEvents(events);
    };
    fetchProducts();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="bg-b">
      <Nav />
      <AnimatePresence>
        {((w ? false : !videoLoaded) || !logoLoaded) && pathname === "/" && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: { duration: 0.5, ease: "easeIn" },
            }}
            className="fixed inset-0 bg-b z-50 grid place-items-center"
          >
            <div className="absolute h-[30vh] w-fit">
              <img src={logo} alt="" className="h-full w-auto pb-10" />
            </div>
            <span className="loader"></span>
          </motion.div>
        )}
      </AnimatePresence>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              events={events}
              setLogoLoaded={setLogoLoaded}
              setVideoLoaded={setVideoLoaded}
              videoLoaded={videoLoaded}
              logoLoaded={logoLoaded}
            />
          }
        />
        <Route path="/bowling" element={<Bowling />} />
        <Route path="/laser-game" element={<Laser />} />
        <Route path="/trampoline" element={<Trampo />} />
        <Route path="/billard-et-jeux" element={<Arcade />} />
        <Route path="/bar" element={<Bar />} />
        <Route
          path="/horaires-et-tarifs"
          element={<Tarifs horaire={horaire} />}
        />
        <Route path="/événements" element={<Events events={events} />} />
        <Route path="/anniversaires" element={<Bday />} />
        <Route path="/groupes-et-entreprises" element={<Groups />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/contact/politique-de-confidentialite"
          element={<Privacy />}
        />
        <Route path="/mentions-legales" element={<Legal />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
