import React, { Fragment, useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineCloseCircle, AiOutlineLeftCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import placeholder from "../assets/logoNoBg.png";

const Event = ({ events, setPopup }) => {
  const [eventDisplayed, setEventDisplayed] = useState(null);
  const { pathname } = useLocation();
  const popupAnimation = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.3,
      },
    },
  };

  const titleAnimation = {
    hidden: { opacity: 0, y: -200 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        bounce: 0.5,
      },
    },
  };

  const weekday = (date) => {
    const options = {
      weekday: "long",
    };
    const dateAndTime = new Date(date);
    return dateAndTime.toLocaleDateString("fr-FR", options);
  };

  const frenchDateAndTimeFormatter = (date) => {
    const options = {
      month: "long",
      day: "numeric",
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    };
    const dateAndTime = new Date(date);
    return dateAndTime.toLocaleDateString("fr-FR", options);
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={popupAnimation}
      onClick={(e) => e.stopPropagation()}
      className={
        "overflow-y-scroll rounded-md h-4/5 w-4/5 relative flex flex-col  items-center  lg:pt-10 px-0 lg:px-20 " +
        (pathname === "/%C3%A9v%C3%A9nements" ? "bg-t px-2" : "bg-b px-10")
      }
    >
      <motion.button
        className={
          "absolute right-6 top-6 z-40 group/close" +
          (pathname === "/%C3%A9v%C3%A9nements" ? " hidden" : " block")
        }
        variants={titleAnimation}
        onClick={() => setPopup(false)}
      >
        <AiOutlineCloseCircle
          onClick={() => setPopup(null)}
          size={50}
          className={
            " text-c2 drop-shadow-[0px_12px_3px_#56bbe5] group-hover/close:drop-shadow-[0px_-0px_3px_#56bbe5] group-hover/close:translate-y-3 shadow-c2 cursor-pointer"
          }
        />
      </motion.button>
      {eventDisplayed === null && (
        <h1 className="mb-10 text-w text-5xl lg:text-7xl mt-20 lg:mt-0 z-10 font-serif">
          Événements
        </h1>
      )}
      {eventDisplayed === null ? (
        events && events.length > 0 ? (
          <div className=" w-full py-10">
            {events.map((event, index) => (
              <button
                className=" border border-w relative group/event odd:text-c2 even:text-c3 flex flex-col-reverse lg:flex-row items-center justify-between w-full lg:w-4/5 mx-auto p-6  first-of-type:rounded-t-md last-of-type:rounded-b-md mb-4 shadow-lg odd:shadow-[0px_0px_5px_5px_#56bbe5,_0px_0px_5px_5px_#56bbe5_inset] even:shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] even:shadow-c3"
                key={event.id}
                onClick={() => setEventDisplayed(index)}
              >
                <>
                  <span className="group-hover/event:opacity-0 group-hover/event:-translate-x-10 text-normal mt-4 lg:mt-0 transition-all duration-200">
                    <span className="capitalize">
                      {event.dateTi ? weekday(event.dateTi) : ""}
                    </span>{" "}
                    {event.dateTi
                      ? frenchDateAndTimeFormatter(event.dateTi)
                      : event.dateT}
                  </span>
                </>
                <span className="font-serif text-2xl group-hover/event:opacity-0 group-hover/event:translate-x-10 transition-all duration-150">
                  {event.title}
                </span>
                <span className="font-serif text-2xl mb-10 group-hover/event:mb-0 opacity-0 absolute top-1/3 lg:top-auto right-1/2 translate-x-1/2 group-hover/event:opacity-100 transition-all duration-150">
                  Plus de détails
                </span>
              </button>
            ))}
          </div>
        ) : (
          <div className="text-w">
            <h3 className="text-xl">
              Nous n'avons aucun événement à annoncé pour le moment.
            </h3>
          </div>
        )
      ) : (
        <div className="">
          <motion.button
            className={
              "absolute top-6 z-40 group/close" +
              (pathname === "/%C3%A9v%C3%A9nements" ? " left-0" : " left-6")
            }
            variants={titleAnimation}
            onClick={() => setEventDisplayed(null)}
          >
            <AiOutlineLeftCircle
              size={50}
              className=" text-c3 drop-shadow-[0px_12px_3px_#FD6E88] group-hover/close:drop-shadow-[0px_-0px_3px_#FD6E88] group-hover/close:translate-y-3 shadow-c2 cursor-pointer"
            />
          </motion.button>
          <motion.h2
            variants={titleAnimation}
            className="font-serif text-2xl lg:text-7xl text-center w-full text-w capitalize mb-4 lg:mt-10 mt-20 lg:mb-20"
          >
            {events[eventDisplayed]?.title}
          </motion.h2>

          <div className="relative flex flex-col lg:flex-row w-full">
            <motion.img
              variants={titleAnimation}
              src={events[eventDisplayed]?.src?.url || placeholder}
              alt={events[eventDisplayed]?.title}
              className="w-full lg:w-1/2 object-cover h-fit lg:sticky top-40 mb-4 lg:mb-10"
            />

            <motion.p
              variants={titleAnimation}
              className="text-w lg:pl-10 pb-10 lg:sticky top-40"
            >
              {events[eventDisplayed]?.description
                .split("\n")
                .map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
            </motion.p>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Event;
