import React from "react";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Form = ({ setConfetti }) => {
  const formik = useFormik({
    initialValues: {
      user_name: "",
      user_last_name: "",
      user_phone: "",
      user_email: "",
      user_topic: "Objet de votre demande...",
      user_message: "",
      user_agreement: false,
    },
    // Validation
    validationSchema: Yup.object({
      user_name: Yup.string().required("Champ obligatoire"),
      user_email: Yup.string()
        .email("Email invalide")
        .required("Champ obligatoire"),
      user_phone: Yup.string()
        .required("Champ obligatoire")
        .min(9, "Numéro invalide"),
      user_topic: Yup.string()
        .required("Champ obligatoire")
        .oneOf(
          [
            "J'ai besoin d'aide",
            "Je veux organiser l'anniversaire de mon enfant chez vous",
            "Je veux réserver une partie de laser game ou du trampoline",
          ],
          "Option invalide"
        ),
      user_message: Yup.string()
        .required("Champ obligatoire")
        .min(25, "Ce champ doit contenir un minimum de 25 caractères"),
      user_agreement: Yup.boolean().oneOf([true], "Champ obligatoire"),
    }),
    onSubmit: async (values) => {
      try {
        const {
          user_name,
          user_last_name,
          user_email,
          user_phone,
          user_topic,
          user_message,
          user_agreement,
        } = values;
        const htmlContent = `
        Nom: ${user_last_name.toUpperCase()} ${user_name} <br>
        Téléphone: ${user_phone}<br>
        Sujet: ${user_topic}<br>
        Message: ${user_message}<br>
        Accepte la politique de confidentialité: ${
          user_agreement ? "Oui" : "Non"
        }
      `;
        const subject = "Contact - Formulaire de contact";
        await sendBdayRequest(htmlContent, user_email, subject).then(() => {
          setConfetti(true);
        });
        Swal.fire({
          icon: "success",
          title: `Merci d'avoir pris le temps de remplir le formulaire!

              Nous vous contacterons dans les plus brefs délais 
              pour confirmer votre anniversaire.`,
          confirmButtonColor: "#56bbe5",
          confirmButtonText: "Fermer",
        });
        formik.resetForm();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops! Une erreur s'est produite. Veuillez recommencer.",
        });
      }
    },
  });

  const sendBdayRequest = async (htmlContent, replyTo) => {
    const subject = "Contact West Parc";
    try {
      const response = await axios.post(
        "https://westparc-server.vercel.app/send-email",
        {
          htmlContent,
          replyTo,
          subject,
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  return (
    <motion.form
      layout
      onSubmit={formik.handleSubmit}
      className="w-full max-w-[800px] h-fit md:h-[450px] border shadow-[0px_0px_10px_10px_#56bbe5] border-c2 p-4 rounded-lg font-sans flex flex-col items-center z-10 gap-4"
    >
      <motion.div
        layout
        className="w-full h-full text-xl text-b flex flex-col md:flex-row items-center justify-center gap-4"
      >
        <motion.div
          layout
          className="md:w-2/5 w-full flex flex-col  items-center justify-center gap-4"
        >
          <div className="flex flex-row  w-full gap-5 items-center">
            <motion.input
              layout
              type="text"
              name="user_name"
              id="user_name"
              placeholder="* Prénom..."
              className="w-full h-10 border border-c2 rounded-md px-4 capitalize"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.user_name}
            />
            {formik.touched.user_name && formik.errors.user_name ? (
              <motion.div layout className="text-c1 text-sm ">
                {formik.errors.user_name}
              </motion.div>
            ) : null}
          </div>
          <div className="flex flex-row w-full  gap-5 items-center">
            <motion.input
              layout
              type="text"
              name="user_last_name"
              id="user_last_name"
              placeholder="Nom..."
              className=" w-full h-10 border border-c2 rounded-md px-4 uppercase"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.user_last_name}
            />
            {formik.touched.user_last_name && formik.errors.user_last_name ? (
              <p layout className=" text-c1 text-sm">
                {formik.errors.user_last_name}
              </p>
            ) : null}
          </div>

          <div className="w-full flex flex-row gap-5 items-center">
            <motion.input
              layout
              type="email"
              name="user_email"
              id="user_email"
              placeholder="* Adresse email..."
              className="w-full h-10 border border-c2 rounded-md px-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.user_email}
            />
            {formik.touched.user_email && formik.errors.user_email ? (
              <p className="text-c1 text-sm ">{formik.errors.user_email}</p>
            ) : null}
          </div>
          <div className="w-full flex flex-row items-center gap-5">
            <motion.input
              layout
              type="text"
              name="user_phone"
              id="user_phone"
              placeholder="* Téléphone..."
              className="w-full h-10 border border-c2 rounded-md px-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.user_phone}
            />
            {formik.touched.user_phone && formik.errors.user_phone ? (
              <p layout className="text-c1 text-sm">
                {formik.errors.user_phone}
              </p>
            ) : null}
          </div>
          <div className="w-full text-b flex flex-row gap-5 items-center">
            <motion.select
              layout
              name="user_topic"
              id="user_topic"
              className=" w-full h-10 border border-c2 rounded-md px-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.user_topic}
            >
              <option value="Objet de votre demande..." disabled>
                Objet de votre demande...
              </option>
              <option value="Je veux réserver une partie de laser game ou du trampoline">
                Je veux réserver une partie de laser game ou du trampoline
              </option>
              <option value="Je veux organiser l'anniversaire de mon enfant chez vous">
                Je veux organiser l'anniversaire de mon enfant chez vous
              </option>
              <option value="Nous sommes un groupe de 15 et plus">
                Nous sommes un groupe de 15 et plus
              </option>
              <option value="Je veux organiser un événement">
                Je veux organiser un événement
              </option>
              <option value="Je veux acheter des ticket CE">
                Je veux acheter des ticket CE
              </option>
              <option value="Autre...">Autre...</option>
            </motion.select>
            {formik.touched.user_topic && formik.errors.user_topic ? (
              <p layout className="text-c1 text-sm ">
                {formik.errors.user_topic}
              </p>
            ) : null}
          </div>
        </motion.div>
        <motion.div
          layout
          className="md:w-3/5 w-full h-52 md:h-full flex flex-col items-start gap-2"
        >
          <motion.textarea
            layout
            name="user_message"
            id="user_message"
            placeholder="* Veuillez nous faire part de votre demande..."
            className="w-full h-full md:h-full border border-c2 rounded-md p-4 resize-none"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user_message}
          />
          {formik.touched.user_message && formik.errors.user_message ? (
            <p className="text-c1 text-sm">{formik.errors.user_message}</p>
          ) : null}
        </motion.div>
      </motion.div>
      <motion.div
        layout
        className=" w-full flex flex-col items-start justify-start gap-2"
      >
        <div className="flex flex-row gap-4">
          <motion.input
            layout
            type="checkbox"
            name="user_agreement"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user_agreement}
            id="user_agreement"
            className="w-5 h-5 cursor-pointer"
          />
          <motion.label
            layout
            htmlFor="user_agreement"
            className="text-w text-sm"
          >
            J'accepte la{" "}
            <Link className="underline" to="/mentions-legales">
              politique de confidentialité
            </Link>
          </motion.label>
        </div>
        {formik.touched.user_agreement && formik.errors.user_agreement ? (
          <p className="text-c1 text-sm ">{formik.errors.user_agreement}</p>
        ) : null}
      </motion.div>
      <motion.button
        layout
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className="w-full disabled:cursor-not-allowed py-4 text-3xl font-serif bg-c2 text-w disabled:opacity-50 disabled:hover:bg-c2 disabled:hover:text-w hover:bg-b hover:text-c2 hover:border-c2 border border-b rounded-md flex items-center justify-center transition-all duration-300"
      >
        {formik.isSubmitting ? (
          <svg
            aria-hidden="true"
            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-c2 fill-c1"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : (
          "Envoyer"
        )}
      </motion.button>
    </motion.form>
  );
};

export default Form;
