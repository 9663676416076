import React, { useMemo } from "react";
import Form from "../components/Form";
import Confetti from "react-confetti";
import { motion } from "framer-motion";
import { SiMinutemailer } from "react-icons/si";

const Contact = () => {
  const [confetti, setConfetti] = React.useState(false);
  const planes = useMemo(
    () =>
      []
        .concat(...Array(20))
        .fill([<SiMinutemailer size={50} color="#FD6E88" />]),
    []
  );

  return (
    <div className="min-h-screen px-10 lg:px-40 lg:h-fit w-screen flex flex-col items-center pt-40 text-5xl font-serif bg-b text-w mb-20">
      <div className="fixed top-40 inset-0 grid grid-cols-3 lg:grid-cols-5 grid-rows-4 z-0 opacity-30">
        {planes.map((plane, i) => (
          <motion.div
            animate={{
              x: [-700, 500],
              y: [700, -500],
              transition: {
                duration: 7,
                delay: i * Math.random() * 0.3,
                repeat: Infinity,
                ease: "linear",
              },
            }}
            key={i}
            className={`justify-self-center origin-center z-0`}
          >
            {plane}
          </motion.div>
        ))}
      </div>
      <h1 className="w-full lg:text-7xl text-center mb-10 z-10">Contact</h1>
      <div className="fixed inset-0 z-0">
        {confetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={1000}
          />
        )}
      </div>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5, type: "tween", ease: "easeIn" },
        }}
        className="origin-top-right z-10"
      >
        <Form setConfetti={setConfetti} />
      </motion.div>
      <h2 className="text-c3 mt-20 mb-10 text-center">
        Rejoignez l'Équipe de West Parc !
      </h2>
      <p className="text-xl font-sans z-10">
        Chez West Parc, nous sommes constamment à la recherche de personnes
        passionnées et dévouées pour rejoindre notre équipe dynamique. Si vous
        êtes quelqu'un qui aime travailler dans un environnement convivial,
        stimulant et axé sur le service client, nous aimerions vous avoir à bord
        !
        <br />
        <br />
        Si vous êtes prêt(e) à apporter votre enthousiasme et votre expertise à
        notre équipe, veuillez soumettre votre candidature en envoyant votre CV
        à westbowling.dinard@gmail.com.
      </p>
    </div>
  );
};

export default Contact;
