import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import Confetti from "react-confetti";
import groups1 from "../assets/groups1.jpeg";

const Groups = () => {
  const [confetti, setConfetti] = useState(false);
  const formik = useFormik({
    initialValues: {
      businessName: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      city: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required("Requis"),
      addressLine1: Yup.string().required("Requis"),
      addressLine2: Yup.string(),
      postalCode: Yup.string().required("Requis"),
      city: Yup.string().required("Requis"),
      name: Yup.string().required("Requis"),
      lastName: Yup.string().required("Requis"),
      email: Yup.string().email("Invalid email address").required("Requis"),
      phone: Yup.string().required("Requis"),
      message: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        const {
          businessName,
          addressLine1,
          addressLine2,
          postalCode,
          city,
          name,
          lastName,
          email,
          phone,
          message,
        } = values;
        const htmlContent = `
        Entreprise : ${businessName}<br>
        Adresse : <br> ${addressLine1}${
          addressLine2 ? ", " + addressLine2 : ""
        }  <br> ${postalCode}, ${city}<br>
        Prénom et nom du contact : ${lastName.toUpperCase()} ${name} <br>
        Téléphone : ${phone}<br>
        Email : ${email}<br>
        Message : ${message}<br>
      `;
        const subject = "Groupe et entreprises - Formulaire de demande";
        await sendBdayRequest(htmlContent, email, subject).then(() => {
          setConfetti(true);
        });
        Swal.fire({
          icon: "success",
          title: `Merci d'avoir pris le temps de remplir le formulaire!
  
              Nous vous contacterons dans les plus brefs délais 
              pour confirmer votre anniversaire.`,
          confirmButtonColor: "#56bbe5",
          confirmButtonText: "Fermer",
        });
        formik.resetForm();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops! Une erreur s'est produite. Veuillez recommencer.",
        });
      }
    },
  });

  const sendBdayRequest = async (htmlContent, replyTo) => {
    const subject = "Groupe et entreprises - Formulaire de demande";
    try {
      const response = await axios.post(
        "https://westparc-server.vercel.app/send-email",
        {
          htmlContent,
          replyTo,
          subject,
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <motion.div
      layout
      className="w-screen flex flex-col-reverse lg:flex-row items-start bg-b text-w pb-20 pt-40 px-10 lg:px-20"
    >
      {confetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={1000}
        />
      )}

      <div className="pb-10 lg:pr-10">
        <h1 className="text-5xl lg:block hidden mx-auto w-fit text-center lg:text-7xl font-serif text-w mb-10">
          Groupes et entreprises
        </h1>
        <div className="flex flex-col-reverse lg:flex-row">
          <div>
            <img
              className="w-full lg:block hidden lg:w-1/2 mb-10 lg:mb-0 lg:ml-4 float-right object-cover rounded-md"
              src={groups1}
              alt="Groupe qui joue au laser game"
            />
            <h2 className="font-serif text-3xl text-c2 mb-4">
              La billeterie CE
            </h2>
            <h3 className="text-xl mb-2">Les avantages</h3>
            <ul className="pl-2 mb-4">
              <li> Aucune date de validité</li>
              <li>Pas Nominatif</li>
              <li>Achat à partir de 50 tickets</li>
            </ul>
            Les tarifs vous serons coomuniqué une fois le formulaire envoyé
            <h2 className="font-serif text-3xl text-c2 my-4">Team building</h2>
            <p>
              Retrouvez du lien social via une activité en team building. West
              parc vous propose des formules personnalisables pour vos
              événements en privatisation totale ou partielle.
              <br />
              <br />
              Envoyez nous votre demande via le formulaire et nous vous
              contacterons pour vous accompagner dans votre projet.
            </p>
          </div>
        </div>
      </div>
      <motion.form
        layout
        onSubmit={formik.handleSubmit}
        className="w-full h-[70vh] mb-10 lg;mb-0 overflow-y-scroll lg:overscroll-contain lg:w-screen lg:sticky shadow-[0px_0px_10px_10px_#56bbe5] top-40 max-w-[400px] p-4 border-2 rounded-lg border-c2 font-sans flex flex-col items-center gap-4 lg:ml-auto z-10"
      >
        <motion.div layout className="h-fit">
          <motion.h2
            layout
            className="text-w font-serif mb-4 text-center text-4xl"
          >
            Formulaire de demande
          </motion.h2>
          <motion.div
            layout
            className="w-full h-fit text-lg text-b flex flex-col md:flex-row items-center justify-center gap-4"
          >
            <motion.div
              layout
              className="w-full flex flex-col  items-center justify-center gap-4"
            >
              <motion.div className="w-full flex flex-row gap-5 items-center">
                <motion.input
                  layout
                  type="businessName"
                  name="businessName"
                  id="businessName"
                  placeholder="* Nom de l'entreprise..."
                  className={
                    formik.touched.businessName && formik.errors.businessName
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.businessName}
                />
                {formik.touched.businessName && formik.errors.businessName ? (
                  <p className="text-c1 text-sm lg:block hidden ">
                    {formik.errors.businessName}
                  </p>
                ) : null}
              </motion.div>
              <motion.div
                layout
                className="w-full flex flex-row gap-5 items-center"
              >
                <motion.input
                  layout
                  className={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  type="text"
                  name="addressLine1"
                  placeholder="* Adresse (ligne 1)..."
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.addressLine1 && formik.touched.addressLine1 ? (
                  <motion.div
                    layout
                    className="text-c1 text-sm lg:block hidden"
                  >
                    {formik.errors.addressLine1}
                  </motion.div>
                ) : null}
              </motion.div>
              <motion.div
                layout
                className="w-full flex flex-row gap-5 items-center"
              >
                <motion.input
                  layout
                  className={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  type="text"
                  name="addressLine2"
                  placeholder="Adresse (ligne 2)..."
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.addressLine2 && formik.touched.addressLine2 ? (
                  <motion.div
                    layout
                    className="text-c1 text-sm lg:block hidden"
                  >
                    {formik.errors.addressLine2}
                  </motion.div>
                ) : null}
              </motion.div>
              <motion.div
                layout
                className="w-full flex flex-row gap-5 items-center"
              >
                <motion.input
                  layout
                  className={
                    formik.touched.postalCode && formik.errors.postalCode
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  type="text"
                  name="postalCode"
                  placeholder="* Code postal..."
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.postalCode && formik.touched.postalCode ? (
                  <motion.div
                    layout
                    className="text-c1 text-sm lg:block hidden"
                  >
                    {formik.errors.postalCode}
                  </motion.div>
                ) : null}
              </motion.div>
              <motion.div
                layout
                className="w-full flex flex-row gap-5 items-center"
              >
                <motion.input
                  layout
                  className={
                    formik.touched.city && formik.errors.city
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  type="text"
                  name="city"
                  placeholder="* Ville..."
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.city && formik.touched.city ? (
                  <motion.div
                    layout
                    className="text-c1 text-sm lg:block hidden"
                  >
                    {formik.errors.city}
                  </motion.div>
                ) : null}
              </motion.div>
              <h2 className="text-w mr-auto">Personne à contacter</h2>
              <motion.div className="flex flex-row items-center gap-2">
                <motion.div className="flex flex-row w-full  gap-5 items-center">
                  <motion.input
                    layout
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="   Nom..."
                    className=" w-full h-10 border-2 border-c2 rounded-md px-4 uppercase"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                </motion.div>

                <motion.div className="flex flex-row  w-full gap-5 items-center">
                  <motion.input
                    layout
                    type="text"
                    name="name"
                    id="name"
                    placeholder="* Prénom..."
                    className={
                      formik.touched.name && formik.errors.name
                        ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                        : "w-full h-10 border-2 border-c2 rounded-md px-4 capitalize"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <motion.div
                      layout
                      className="text-c1 text-sm lg:block hidden"
                    >
                      {formik.errors.name}
                    </motion.div>
                  ) : null}
                </motion.div>
              </motion.div>
              <motion.div className="w-full flex flex-row gap-5 items-center">
                <motion.input
                  layout
                  type="email"
                  name="email"
                  id="email"
                  placeholder="* Email..."
                  className={
                    formik.touched.email && formik.errors.email
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-c1 text-sm lg:block hidden ">
                    {formik.errors.email}
                  </p>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row items-center gap-5">
                <motion.input
                  layout
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="* Téléphone..."
                  className={
                    formik.touched.phone && formik.errors.phone
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p layout className="text-c1 text-sm lg:block hidden">
                    {formik.errors.phone}
                  </p>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row items-center gap-5">
                <motion.textarea
                  layout
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Objet de la demande..."
                  className="w-full h-20 border-2 border-c2 rounded-md py-2 px-4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.button
            layout
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
            className="w-full mt-4 py-4 disabled:cursor-not-allowed text-3xl font-serif bg-c2 text-w disabled:opacity-50 disabled:hover:bg-c2 disabled:hover:text-w hover:bg-b hover:text-c2 hover:border-c2 border-2 border-b rounded-md flex items-center justify-center transition-all duration-300"
          >
            {formik.isSubmitting ? (
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-c2 fill-c1"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              "Soumettre"
            )}
          </motion.button>
        </motion.div>
      </motion.form>
      <img
        className="w-full lg:hidden lg:w-1/2 mb-10 lg:ml-4 float-right object-cover rounded-md"
        src={groups1}
        alt="Groupe qui joue au laser game"
      />
      <h1 className="text-5xl lg:hidden mx-auto w-fit text-center lg:text-7xl font-serif text-w mb-10">
        Groupes et entreprises
      </h1>
    </motion.div>
  );
};

export default Groups;
