import React from "react";
import Event from "../components/Event";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { LiaMicrophoneAltSolid } from "react-icons/lia";
import { GiPartyPopper } from "react-icons/gi";
import { motion } from "framer-motion";

const Events = ({ events }) => {
  const w = window.innerWidth < 992;
  const clocks = [].concat(
    ...Array(w ? 3 : 4).fill([
      <MdOutlineEmojiEvents color="#FD6E88" size={100} />,
      <LiaMicrophoneAltSolid color="#FD6E88" size={100} />,
      <GiPartyPopper color="#FD6E88" size={100} />,
    ])
  );
  return (
    <div className="w-full pt-40 min-h-screen pb-20 flex flex-col items-center">
      <div className="fixed top-40 inset-0 grid grid-cols-3 lg:grid-cols-4 grid-rows-3 z-0 opacity-30">
        {clocks.map((clock, i) => (
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{
              opacity: 1,
              y: Math.random() * 100 - 50,
              transition: {
                delay: i * 0.1,
                type: "spring",
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 3,
              },
            }}
            key={i}
            className={`justify-self-center origin-center z-0`}
          >
            {clock}
          </motion.div>
        ))}
      </div>
      <Event events={events} />
    </div>
  );
};

export default Events;
