import React from "react";

const Privacy = () => {
  return (
    <div className="text-w pt-52 px-40 pb-20">
      Politique de Confidentialité de West Parc <br />
      <br /> Date de dernière mise à jour : 21 oct. 2023
      <br />
      <br />
      1. Introduction
      <br />
      <br />
      West Parc respecte la vie privée de ses utilisateurs et s'engage à
      protéger leurs données personnelles. Cette Politique de Confidentialité
      explique comment nous collectons, utilisons, et protégeons vos données
      personnelles lorsque vous utilisez notre site web et nos services.
      <br />
      <br />
      2. Collecte des Données
      <br />
      <br />
      Nous pouvons collecter des informations personnelles telles que :
      <br />
      <br />
      Nom et prénom <br /> Adresse e-mail
      <br /> Numéro de téléphone
      <br />
      Autres informations pertinentes <br />
      <br /> 3. Utilisation des Données
      <br />
      <br />
      Nous utilisons vos données pour :
      <br />
      <br />
      Traiter vos demandes et réservations <br /> Améliorer notre site web et
      nos services
      <br /> Vous envoyer des informations sur nos services
      <br /> Réaliser des analyses statistiques <br />
      <br /> 4. Consentement
      <br />
      <br />
      En fournissant vos données personnelles, vous consentez à leur utilisation
      conformément à cette Politique de Confidentialité.
      <br />
      <br />
      5. Partage des Données
      <br />
      <br />
      Nous ne vendons, n'échangeons, ni ne transférons vos données personnelles
      à des tiers sans votre consentement, sauf obligation légale.
      <br />
      <br />
      6. Sécurité des Données
      <br />
      <br />
      Nous mettons en œuvre diverses mesures de sécurité pour protéger vos
      données personnelles.
      <br />
      <br />
      7. Cookies
      <br />
      <br />
      Nous utilisons des cookies pour améliorer l'expérience utilisateur sur
      notre site web.
      <br />
      <br />
      8. Droits des Utilisateurs
      <br />
      <br />
      Conformément aux lois applicables, vous avez le droit d'accéder, de
      rectifier, et de supprimer vos données personnelles.
      <br />
      <br />
      9. Modifications de la Politique de Confidentialité
      <br />
      <br />
      Nous pouvons mettre à jour cette Politique de Confidentialité pour
      refléter les changements dans nos pratiques de données. Les modifications
      seront publiées sur cette page avec une mise à jour de la date de
      "Dernière mise à jour".
      <br />
      <br />
      10. Contact
      <br />
      <br />
      Pour toute question relative à cette Politique de Confidentialité, vous
      pouvez nous contacter à :
      <br />
      <br />
      West parc <br /> 1 Rue des Villes Billy,
      <br /> 35780 La Richardais <br />
      Téléphone: 02 23 16 97 68 <br /> Email: westbowling.dinard@gmail.com
    </div>
  );
};

export default Privacy;
