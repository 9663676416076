import React from "react";
import gun from "../assets/gun.png";
import trampo from "../assets/trampo.png";
import bowling from "../assets/bowling1.png";
import { Link } from "react-router-dom";
import { TfiAlarmClock } from "react-icons/tfi";
import { TbPigMoney } from "react-icons/tb";
import { motion } from "framer-motion";
import { useMemo } from "react";

const Tarifs = ({ horaire }) => {
  const schedule = [
    { day: "Lundi", schedule: "Fermé" },
    { day: "Mardi", schedule: "Fermé" },
    { day: "Mercredi", schedule: "14:00 - 00:00" },
    { day: "Jeudi", schedule: "17:00 - 00:00" },
    { day: "Vendredi", schedule: "17:00 - 01:00" },
    { day: "Samedi", schedule: "14:00 - 1:00" },
    { day: "Dimanche", schedule: "14:00 - 19:00" },
  ];
  const vacances = [
    { day: "Lundi", schedule: "14:00 - 0:00" },
    { day: "Mardi", schedule: "14:00 - 0:00" },
    { day: "Mercredi", schedule: "14:00 - 0:00" },
    { day: "Jeudi", schedule: "14:00 - 0:00" },
    { day: "Vendredi", schedule: "14:00 - 1:00" },
    { day: "Samedi", schedule: "14:00 - 1:00" },
    { day: "Dimanche", schedule: "14:00 - 19:00" },
  ];

  const ete = [
    { day: "Lundi", schedule: "14:00 - 1:00" },
    { day: "Mardi", schedule: "14:00 - 1:00" },
    { day: "Mercredi", schedule: "14:00 - 1:00" },
    { day: "Jeudi", schedule: "14:00 - 1:00" },
    { day: "Vendredi", schedule: "14:00 - 1:00" },
    { day: "Samedi", schedule: "14:00 - 1:00" },
    { day: "Dimanche", schedule: "14:00 - 19:00" },
  ];

  const dayOfTheWeekFrench = (day) => {
    switch (day) {
      case "Monday":
        return "Lundi";
      case "Tuesday":
        return "Mardi";
      case "Wednesday":
        return "Mercredi";
      case "Thursday":
        return "Jeudi";
      case "Friday":
        return "Vendredi";
      case "Saturday":
        return "Samedi";
      case "Sunday":
        return "Dimanche";
      default:
        return "Fermé";
    }
  };
  const scheduleList = horaire?.horaire;
  const setSchedule = () => {
    if (scheduleList === "vacances") {
      return vacances;
    } else if (scheduleList === "ete") {
      return ete;
    } else {
      return schedule;
    }
  };
  const activeSchedule = setSchedule();
  const w = window.innerWidth < 992;
  const icons = [bowling, gun, trampo];
  const clocks = useMemo(
    () =>
      [].concat(
        ...Array(w ? 6 : 10).fill([
          <TfiAlarmClock color="#FD6E88" size={100} />,
          <TbPigMoney color="#FD6E88" size={100} />,
        ])
      ),
    [w]
  );

  return (
    <div className="min-h-screen w-screen flex flex-col items-center pt-36 text-xl lg:text-3xl font-serif bg-b ">
      <div className="fixed top-40 inset-0 grid grid-cols-3 lg:grid-cols-5 grid-rows-4 z-0 opacity-30">
        {clocks.map((clock, i) => (
          <motion.div
            initial={{ opacity: 0, rotate: 0 }}
            animate={{
              opacity: 1,
              rotate: Math.random() * 360,
              transition: {
                delay: i * 0.1,
                type: "spring",
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 3,
              },
            }}
            key={i}
            className={`justify-self-center origin-center z-0`}
          >
            {clock}
          </motion.div>
        ))}
      </div>
      <section className="w-full flex flex-col gap-2 items-center z-10">
        <h1 className="mb-10 text-w text-5xl lg:text-7xl z-10">Horaires</h1>
        {activeSchedule?.map((date, index) => (
          <div
            key={index}
            className={`font-sans capitalize hover:scale-110 transition-all duration-300 flex flex-row border first-of-type:rounded-t-md bg-t last-of-type:rounded-b-md p-4 ${
              date.schedule === "Fermé"
                ? "text-c1 border-w shadow-[0px_0px_5px_5px_#EE2420,_0px_0px_5px_5px_#EE2420_inset] hover:shadow-[0px_0px_10px_10px_#EE2420_inset,_0px_0px_10px_10px_#EE2420]"
                : "border-w text-c2 shadow-[0px_0px_5px_5px_#56bbe5,_0px_0px_5px_5px_#56bbe5_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] transition-all duration-300"
            }
                ${
                  date.day ===
                    dayOfTheWeekFrench(
                      new Date().toLocaleDateString("en-US", {
                        weekday: "long",
                      })
                    ) && " scale-110 "
                } justify-between w-4/5 lg:w-1/2`}
          >
            <p className={""}>{date.day}</p>
            <p className="font-sans">{date.schedule}</p>
          </div>
        ))}
      </section>
      <section className="flex flex-col text-w items-center w-screen px-10 lg:px-40 mb-40 z-10">
        <h2 className="mb-10 mt-20 text-5xl lg:text-7xl">Tarifs</h2>
        <p className="font-sans mb-10">
          <span className="text-c2">Un seul et même tarif</span> pour le
          bowling, le laser game et le trampoline !
        </p>
        <p className="font-sans text-c3 mb-10">
          Choisissez 1, 2 ou 3 activités.
        </p>
        <div className="flex flex-row items-center justify-between w-4/5 lg:w-4/5 mb-10">
          {icons.map((icon, index) => (
            <img key={index} src={icon} alt="icon" className="w-14 lg:w-40" />
          ))}
        </div>
        <div className="lg:hidden flex flex-col items-center gap-10">
          <div className="flex-1 flex flex-col items-center">
            <h2 className="mb-10 ">Du lundi au vendredi 19h00</h2>
            <p className="font-sans mb-10">
              <span className="text-c2">1 activité</span> : 8€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">2 activités</span> : 14€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">3 activités</span> : 18€/pers.
            </p>
          </div>
          <div className="flex-1 flex flex-col items-center">
            <h2 className="mb-10">
              De vendredi 19h00 à dimanche soir, <br /> vacances scolaires,{" "}
              <br />
              veille de fêtes (19h00),
              <br /> jours fériés
            </h2>
            <p className="font-sans mb-10">
              <span className="text-c2">1 activité</span> : 9€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">2 activités</span> : 16€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">3 activités</span> : 20€/pers.
            </p>
          </div>
          <div className="flex-1 flex flex-col items-center">
            <h2 className="mb-10">
              Tarif étudiant tous les jeudis <br /> hors vacances scolaires
            </h2>
            <p className="font-sans mb-10">
              <span className="text-c2">1 activité</span> : 6€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">2 activités</span> : 11€/pers.
            </p>
            <p className="font-sans mb-10">
              <span className="text-c2">3 activités</span> : 15€/pers.
            </p>
          </div>
        </div>
        <div className="hidden lg:grid font-sans grid-cols-4 gap-2 w-full">
          <div />
          <h2 className="border-2 rounded-tl-md font-serif border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2">
            Du lundi au vendredi 19h00
          </h2>
          <h2 className="border-2 font-serif border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2">
            {" "}
            Du vendredi 19h00 au dimanche soir et vacances *
          </h2>
          <h2 className="border-2 rounded-tr-md font-serif border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2">
            Tarif étudiant tous les jeudis hors vacances *
          </h2>
          <p className="border-2 rounded-tl-md border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            1 activité
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            8€/pers.
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            9€/pers.
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            6€/pers.
          </p>
          <p className="border-2 border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            2 activités
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            14€/pers.
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            16€/pers.
          </p>
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            11€/pers.
          </p>
          <p className="border-2 rounded-bl-md border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            3 activités
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            18€/pers.
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            20€/pers.
          </p>
          <p className="border-2 rounded-br-md border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-4 py-2 text-center">
            15€/pers.
          </p>
        </div>
        <p className="lg:block hidden mt-4 w-full text-end mb-10">
          * vacances scolaires, veille de fêtes (19h00), jours fériés
        </p>
        <p className="w-full font-sans text-xl">
          <span className="font-serif  text-c3">Réservations :</span> Le bowling
          se réserve à partir de groupes de 20 personnes. <br />
          Pour le trampoline et le laser game, nous vous invitons à réserver par
          téléphone ( 02 23 16 97 68 ) pour vous assurer le créneau horaire que
          vous souhaitez.
          <br />
          Pour vos événements d’entreprise, les grands groupes (plus de 20
          personnes), veuillez vous rapporter à notre rubrique{" "}
          <Link
            to="/groupes-et-entreprises"
            className=" underline hover:text-c2"
          >
            groupes et entreprises
          </Link>
          <br />
          <br />
          <span className="font-serif  text-c2">Bowling</span> : Location de
          chaussures comprise dans le tarif. <br />
          Chaussettes obligatoire. (en vente sur place 1.50€)
          <br />
          <br />
          <span className="font-serif text-c3">Trampoline</span> : Chaussettes
          antidérapantes obligatoires. (en vente sur place 2€)
        </p>
      </section>
    </div>
  );
};

export default Tarifs;
