import React from "react";
import bowling1 from "../assets/bowling2.jpg";
import { motion } from "framer-motion";
const Bowling = () => {
  return (
    <div className=" w-screen py-40  flex flex-col items-center gap-10 text-5xl font-serif bg-b text-w">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className="text-c2 underline lg:text-7xl"
      >
        Bowling
      </motion.h2>
      <section className="flex flex-col px-10 lg:px-0 lg:flex-row items-start gap-4 pb-20">
        <motion.div
          initial={{
            x: "-100%",
            borderTopRightRadius: "100%",
            borderBottomRightRadius: "100%",
            width: "25%",
            height: "25%",
          }}
          animate={{
            x: "0%",
            borderTopRightRadius: "2%",
            borderBottomRightRadius: "2%",
            width: "100%",
            height: "100%",
            transition: { duration: 0.5 },
          }}
          className="lg:sticky lg:rounded-none rounded-lg top-60 mx-auto overflow-hidden grid place-content-center"
        >
          <motion.img
            initial={{ scale: 2 }}
            animate={{
              scale: 1,
              transition: { duration: 0.5 },
            }}
            src={bowling1}
            alt="pistes de bowling"
            className="w-full"
          />
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }}
          className="font-sans text-xl lg:pr-40"
        >
          Le Bowling de West Parc est équipé de
          <span className="bg-c3 rounded-sm p-0 px-1 inline whitespace-nowrap">
            16 pistes
          </span>{" "}
          de Bowling ou vous pourrez jouer en famille ou entre amis pour vous
          challenger et vous faire plaisir. <br />
          <br /> Nos pistes de Bowling sont accessibles aux personnes
          handicapées et aux enfants à partir de 5 ans. Des toboggans et des
          boules légères sont à disposition.
          <br />
          <br />
          Toutes nos pistes sont équipées de barrières pour que les enfants
          puissent jouer comme des grands.
          <br />
          <br />{" "}
          <span className="text-c2">
            {" "}
            L’activité Bowling ne se réserve que pour des groupes d’au moins 20
            personnes.
          </span>{" "}
          <br />
          <br />
          Petit truc : Pendant les jours d’affluence et les vacances pluvieuses,
          choisissez d’arriver après 17h : vous éviterez d’attendre.
          <br />
          <br />{" "}
          <span className="font-serif mb-4 text-3xl text-c3">
            Services et accessibilité{" "}
          </span>{" "}
          <br />
          <br />
          Accessible aux personnes à mobilité réduite
          <br /> Adultes tous niveaux
          <br />
          Enfants tous niveaux
        </motion.p>
      </section>
      <section className="text-xl font-sans px-10 lg:px-40">
        <h2 className="underline sticky top-[120px] lg:top-36 w-full bg-b text-center font-serif text-5xl mb-10 text-c2">
          En savoir plus sur le Bowling
        </h2>{" "}
        <h3 className="font-serif text-3xl mb-6 text-c3"> Les boules </h3>{" "}
        <p className="">
          {" "}
          Des boules sont généralement mises à la disposition des participants
          aux parties de bowling. Elles possèdent chacune un diamètre{" "}
          <strong> de 22 cm</strong>, mais leur poids peut varier d’une boule à
          l’autre. Vous avez la possibilité d’emmener vos propres boules si
          celles proposées ne vous conviennent pas : c’est-à-dire si elles sont
          trop lourdes pour vous, ou si la taille des trous n’est pas adaptée à
          celle de vos doigts.
          <strong>
            Vous pouvez choisir des boules moins lourdes en respectant le
            diamètre conventionnel,
          </strong>
          et faire percer des trous à la taille de vos doigts.
          <br />
          <br />
          En dehors du diamètre et du poids, les boules de bowling se déclinent
          généralement sous deux formes, chacune d’entre elles présentant des
          caractéristiques spécifiques. On distingue d’une part des boules sans
          noyau, qui permettent de faire des lancers en ligne droite sur la
          piste de bowling. Elles sont couramment appelées boules ligneuses.
          D’un autre côté, on a les boules dénommées boules suiveuses. Pourvues
          de noyau, ces dernières permettent d’effectuer des lancers en donnant
          à la boule une trajectoire en courbe.
        </p>
        <br />
        <h2 className="font-serif text-3xl mb-6x text-c3">
          Règles concernant les quilles
        </h2>
        <br />
        <p>
          Une partie de bowling se pratique avec 10 quilles de 1,5 kg chacune,
          et mesurant une trentaine de centimètres de hauteur. Disposées au bout
          de la piste, les unes à côté des autres, avec à chaque fois un léger
          décalage vers l’arrière. Elles portent toutes un numéro compris entre
          1 et 10. La quille portant le numéro 10 étant celle située le plus en
          arrière, alors que celle portant le numéro 1 se situe sur la première
          ligne droite en fin de piste.
        </p>
        <br />
        <h2 className="font-serif text-3xl mb-6 text-c3">
          Dimensions de la piste
        </h2>
        <br />
        <p>
          La piste de bowling a des mensurations standards. Toujours tracée en
          ligne droite, elle mesure à peu près 20 mètres de longueur pour une
          largeur de 1 mètre. Plusieurs flèches sont disposées à différents
          endroits de cette piste pour servir de repères aux joueurs.
        </p>
        <br />
        <h2 className="font-serif text-3xl mb-6 text-c3">Règles du jeu</h2>
        <br />
        <p>
          Une partie de bowling comporte 10 tours appelés « frames » dans le
          jargon du jeu. Elle peut rassembler plusieurs joueurs, et chacun d’eux
          a droit à deux lancers de boules à chaque frame pour essayer de faire
          tomber le plus grand nombre de quilles possible. Lorsqu’un joueur
          réussit à abattre les 10 quilles au premier lancer, on dit qu’il a
          réalisé un « strike » ou « abat », et il n’a plus besoin d’un second
          lancer. On parle par contre de « spare » lorsque c’est au deuxième
          lancer que toutes les quilles sont abattues. S’il reste encore des
          quilles après le second lancer, c’est un « trou ».
          <br />
          <br />
          Le comptage des points obtenus par les joueurs se fait après chaque
          frame. Pour un « trou », le nombre de points obtenus est égal au
          nombre de quilles abattues. Lorsqu’il réalise un « spare », le joueur
          obtient un nombre de points équivalent à 10 plus le double du nombre
          de quilles qu’il abat au lancer suivant. En cas d’abattage des 10
          quilles au premier lancer, le nombre de points obtenus est de 10, plus
          les deux boules suivantes, ces dernières comptant double :
          c’est-à-dire une fois dans le tour en cours et une fois dans le ou les
          tours suivant (s).
        </p>
        <br />
        <br />
      </section>
      <section className="px-10 lg:px-40">
        <h2 className="underline w-full sticky top-[120px] lg:top-36 bg-b text-center font-serif text-5xl mb-10 text-c2">
          {" "}
          L’histoire du bowling{" "}
        </h2>
        <p className="text-xl font-sans">
          S'il est vraisemblable que l'homme préhistorique s'adonnait déjà à une
          activité proche des quilles, il semble plus réaliste d'affirmer que ce
          jeu trouve son origine sous l'Egypte pharaonique, comme l'attestent
          nombre de bas-reliefs découverts sur les murs des temples des rives du
          Nil (celui de Philae, près d'Assouan, en est probablement le plus bel
          exemple).
          <br />
          <br />
          En 1930, Sir Flinder Petrie, égyptologue britannique, faisant des
          fouilles autour de la sépulture d'un enfant qui avait été enseveli
          environ 3200 ans avant J.C., mit à jour près du Caire une curieuse
          collection d'objets : 3 pierres rondes et 9 quilles coniques taillées
          dans la pierre et quelques unes dans le marbre. Après examen, il
          déclara que ces objets étaient sans nul doute une forme grossière de
          bowling.
          <br />
          <br />
          L'origine du " Jeu de Quilles " se perd donc dans la nuit des temps et
          l'on peut dire que, depuis que l'homme existe, il a essayé de mettre
          des morceaux de bois debout pour les abattre avec un projectile
          quelconque. La forme et le poids des quilles, la grosseur des boules,
          le mode de jeu, diffèrent selon les régions, mais partout, le principe
          est le même: " faire tomber des quilles ". Mais si l'"Odyssée", chef
          d'œuvre du grand poète grec Homère, fait également état de l'existence
          des quilles dans l'Antiquité hellénistique, ce sport n'écrit ses
          lettres de noblesse qu'à partir du Moyen Age.
          <br />
          <br />
          Ce jeu traversa les siècles, laissant quelques traces de ci, de là,
          notamment dans les registres des paroisses des clergés allemand et
          hollandais aux 13ème et 14ème siècles. Les premiers colons anglais,
          allemands et hollandais importèrent en Amérique leur jeu et leurs
          règles. Le mot " bowling " apparaît pour la première fois dans les
          archives de la ville de Jamestown en Virginie en 1611.
          <br />
          <br />
          Dans un élan d'exotisme qui le pousse à s'intéresser aux pratiques
          étrangères, le compositeur Wolfgang Amadeus Mozart lui consacre son
          Trio "des Quilles" pour piano, clarinette et alto. Pour l'anecdote, il
          semble que Louis XVI ait également pratiqué notre discipline quelques
          mois avant de "perdre la tête"... A ce stade de l'histoire, il est bon
          de signaler que le sport de quilles est également à l'origine d'une
          autre discipline, plus répandue : le bowling.
          <br />
          <br />
          Le mot " bowling ", de l'anglais « to bowl » c’est-à-dire « rouler »
          ou « lancer », est apparu pour la première fois en 1611 dans les
          archives de la ville de Jamestown en Virginie. Les premiers colons
          allemands, hollandais et anglais importèrent en Amérique leur jeu et
          leurs règles.
          <br />
          <br />
          Le premier bowling couvert américain, " le Knickerbocker alleys "
          situé dans le bas Manhattan, fut construit vers 1840. Les pistes
          étaient faites en argile cuite et les 9 quilles disposées en diamant.
          Le jeu devint si populaire qu'il fut vite la proie des parieurs en
          tous genres. Devant les sommes colossales qui étaient mises en jeu, le
          gouverneur de l'état du Connecticut décréta en 1841 qu'il était
          dorénavant interdit de jouer au bowling à 9 quilles ou de conserver
          des pistes. Les plus acharnés parmi les joueurs eurent l'idée géniale
          de rajouter une 10ème quille, de les disposer en triangle et non plus
          en diamant. La loi était contournée, le vrai bowling était né.
          <br />
          <br />
          Vers 1890, le jeu était très populaire aux Etats-Unis, mais les règles
          variaient notablement d'un état à l'autre, ce qui rendait toute
          rencontre sportive pratiquement impossible. Le 9 septembre 1895 à New
          York, une réunion des principaux propriétaires de pistes de bowling
          établit des règles communes, concernant le jeu et le matériel : les
          pistes seraient dorénavant en bois d'érable et feraient 60 pieds de
          longueur (18,29 m) ; les quilles mesureraient 15 inches (38 ,09 cm) et
          les boules feraient 16 pouces et demi de circonférence (42 cm). Lors
          de cette même réunion, fut créée l'American Bowling Congress (A.B.C.).
          Cet organisme régit encore de nos jours le bowling dans le monde ;
          tout nouveau matériel ou innovation technique doit être approuvé par
          l'A.B.C. avant d'être utilisé par les joueurs du monde entier.
          <br />
          <br />
          En France, les premières pistes de bowling apparaissent au moment de
          la première guerre mondiale, notamment dans les casernes américaines
          et quelques pistes privées sont très fréquentées dans les cafés "
          chics " des Champs Elysées.
          <br />
          <br />
          En 1931, George Becker, propriétaire d'un bowling à Pearl River (N.Y.)
          se met à penser à une machine qui pourrait remettre les quilles en
          place automatiquement (jusque là, c'étaient de jeunes garçons ou des
          chômeurs qui relevaient les quilles manuellement pour quelques dollars
          par jour, ce qui faisait qu'une partie de bowling pouvait durer
          jusqu'à deux heures. L'appareil était encore embryonnaire quand il le
          proposa à la compagnie " American Machine and Foundry " (A.M.F.) un
          fabriquant de machines pour la boulangerie, le tabac et les
          appareillages industriels. Le projet fut mis en veilleuse pendant la
          seconde guerre mondiale et apparut en public pour la première fois en
          1946 lors d'une démonstration à Buffalo. Tous les propriétaires
          présents furent conquis par cette machine merveilleuse qui allait leur
          faire gagner des millions de dollars.
          <br />
          <br />
          Le premier centre officiellement équipé fut le " Bowl-O-Drome " de
          Mont Clemens dans le Michigan en 1951.
          <br />
          <br />
          En France, le premier centre privé important fut inauguré à Biarritz
          en 1961, puis vint le fameux bowling du bois de Boulogne à Paris à la
          fin de la même année (tous deux ont disparu aujourd'hui). A l'heure
          actuelle, il existe environ 200 centres de bowling dans l'Hexagone.
          <br />
          <br />
          Vers la fin des années 80, apparaît la seconde grande évolution du
          bowling : l'informatique. Désormais, tout paraît facile à tout un
          chacun, plus besoin de compter les points, l'ordinateur s'en charge.
          Les scores sont visibles par tous sur des écrans de TV ce qui ajoute à
          la convivialité des rencontres entre amis.
          <br />
          <br />
          Le bowling, un jeu vieux comme le monde et pourtant toujours aussi
          passionnant ! Un jeu où l'on prend plaisir, où vous prendrez plaisir à
          faire tomber ces sacrées quilles qui n'en font qu'à leur tête.
        </p>
      </section>
    </div>
  );
};

export default Bowling;
