import React from "react";
import arcade1 from "../assets/arcade1.jpg";
import arcade2 from "../assets/arcade2.jpg";
import arcade3 from "../assets/arcade3.jpg";
import arcade4 from "../assets/arcade4.jpg";
import arcade5 from "../assets/arcade5.jpg";
import { motion } from "framer-motion";

const Arcade = () => {
  return (
    <div className=" w-screen py-40  flex flex-col items-center gap-10 text-5xl font-serif bg-b text-w">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className="text-c2 lg:text-7xl underline text-center"
      >
        Billard et jeux d'arcades
      </motion.h2>

      <section className="flex flex-col px-10 lg:px-0  lg:flex-row items-start gap-4 pb-20">
        <motion.div
          initial={{
            x: "-100%",
            borderTopRightRadius: "100%",
            borderBottomRightRadius: "100%",
            width: "25%",
            height: "25%",
          }}
          animate={{
            x: "0%",
            borderTopRightRadius: "2%",
            borderBottomRightRadius: "2%",
            width: "100%",
            height: "100%",
            transition: { duration: 0.5 },
          }}
          className="lg:sticky lg:rounded-none rounded-lg top-60 mx-auto overflow-hidden grid place-content-center"
        >
          <motion.img
            initial={{ scale: 2 }}
            animate={{
              scale: 1,
              transition: { duration: 0.5 },
            }}
            src={arcade1}
            alt="pistes de bowling"
            className="w-full"
          />
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }}
          className="font-sans text-xl lg:pr-40"
        >
          <span className="text-c2 font-serif text-3xl">Billard</span>
          <br />
          <br />
          Emblème de la convivialité et de l’élégance{" "}
          <span className="bg-c2 p-0 px-1 rounded-sm"> 8 billards</span> de pool
          anglais attendent votre stratégie et votre adresse.
          <br />
          <br />
          <span className="font-serif text-3xl mb-6x text-c2">
            Comment jouer au billard pool ?
          </span>
          <br />
          <br />
          <span className="bg-c2 p-0 px-1 rounded-sm">Le pool anglais</span>
          <br />
          <br />
          Le jeu se déroule sur une table de billard à 6 poches. Mais quel est
          le but du jeu ? Le joueur gagne la partie lorsqu'il a empoché
          l'ensemble de ses 7 billes de couleurs (rouge ou jaune) et qu'il
          empoche à la fin la bille noire.
          <br />
          <br />
          <span className="text-c2 font-serif text-3xl">
            Nos jeux d'arcades
          </span>
          <br />
          <br />
          Le plaisir de jeux simples et tellement exitants vous attendent à West
          Parc, vous pourrez mesurer votre force ou votre adresse sur des jeux
          tels que le palet, les fléchettes, le Boxer, le Basket et meme un
          flipper pour les nostalgiques.
        </motion.p>
      </section>
      <div className="flex flex-row h-96">
        <img
          src={arcade3}
          alt="Jeux d'arcades"
          className="w-1/2 object-cover"
        />
        <img
          src={arcade5}
          alt="Jeux d'arcades"
          className="w-1/2 object-cover"
        />
      </div>
      <section className="text-xl font-sans px-10 lg:px-40">
        <h2 className=" top-36 w-full bg-b text-center font-serif text-5xl mb-10 text-c2">
          À l'origine, le billard se jouait sans table
        </h2>
        <p>
          <img
            src={arcade2}
            alt="Salle de pool"
            className="float-right w-full md:w-1/3 object-cover rounded-md"
          />
          Beaucoup s’accordent à dire que le billard était pratiqué à-même le
          sol à ses débuts. Dans plusieurs pays d’Europe, il se joue selon des
          modalités légèrement différentes mais le principe de base est là : il
          s’agit de faire passer des billes ou des boules dans des arceaux en
          bois à l’aide de queues qui ressemblent davantage à des crosses de
          cricket.
        </p>
        <br />
        <br />
        <h2 className=" top-36 w-full bg-b text-center font-serif text-5xl mb-10 text-c2">
          Au moyen âge, le billard se met à table
        </h2>
        <p>
          On attribue la création de la première table de billard à une
          initiative de Louis XI, en 1469. Souffrant du dos, il avait demandé à
          avoir une table pour jouer à hauteur d’homme. Sur cette première
          table, rudimentaire, était posée une pierre, elle-même recouverte d’un
          drap. Peu à peu, le jeu évolue mais reste réservé à la noblesse.
          <br />
          <br />
          Plus tard, le billard reste une activité élitiste. En attestent des
          gravures datant du XVIIème siècle, représentant des scènes de jeu
          notamment une où on voit Louis XIV s’adonner à cette pratique. Les
          règles ne sont pas encore celles qu’on connait.
        </p>{" "}
        <br />
        <br />
        <h2 className="  top-36 w-full bg-b text-center font-serif text-5xl mb-10 text-c2">
          À l'origine, le billard se jouait sans table
        </h2>
        <p>
          Beaucoup s’accordent à dire que le billard était pratiqué à-même le
          sol à ses débuts. Dans plusieurs pays d’Europe, il se joue selon des
          modalités légèrement différentes mais le principe de base est là : il
          s’agit de faire passer des billes ou des boules dans des arceaux en
          bois à l’aide de queues qui ressemblent davantage à des crosses de
          cricket.
        </p>
        <br />
        <br />
        <h2 className="  top-36 w-full bg-b text-center font-serif text-5xl mb-10 text-c2">
          Le billard devient populaire
        </h2>
        <p>
          Au XVIIIème siècle, de nombreuses variantes du billard voient le jour
          et le jeu se popularise notamment grâce aux académies dans lesquelles
          chacun peut venir jouer. Elles poussent comme des champignons à Paris.
          A la fin du XVIIIème siècle, on compte plus de 800 salles de ce type
          dans la capitale. La table évolue également. Des gravures en couleurs
          montrent l’introduction d’un tapis vert, l’apparition de bandes et des
          queues qui remplacent désormais les crosses.
          <br />
          <br />
          <img
            src={arcade4}
            alt="Salle de pool"
            className="float-left w-full md:w-1/3 my-2 mr-4 object-cover rounded-md"
          />
          C’est au XIXème siècle que les formes de billard actuelles voient le
          jour. C’est également à cette période qu’il s’exporte hors d’Europe.
          En arrivant aux Etats-Unis vers 1800, le billard connait un
          développement bien plus rapide que sur le vieux continent. De
          véritables compétitions internationales y sont crées et le billard
          devient un divertissement prisé de tous. Deux types de jeu cohabitent
          à cette époque : le pool et la carambole, connue également comme étant
          le billard français. Le billard continue d’évoluer notamment en raison
          des améliorations du matériel. On considère que le billard français
          tel qu’il est pratiqué aujourd’hui a été créé en 1850.
          <br />
          <br />
          Aujourd’hui, le billard est pratiqué par des millions de personnes à
          travers le monde, dans ses déclinaisons françaises, britanniques ou
          américaines. Une autre forme de billard est également pratiquée de nos
          jours : le billard artistique. Les « trickshots » sont des coups
          étonnants qui nécessitent une précision redoutable.
        </p>
      </section>
    </div>
  );
};

export default Arcade;
