import React from "react";
import trampo1 from "../assets/trampo1.jpg";
import trampoVid from "../assets/trampoVid.mp4";
import { motion } from "framer-motion";

const Trampo = () => {
  return (
    <div className=" w-screen py-40 px-10 lg:px-0  flex flex-col items-center gap-10 text-5xl font-serif bg-b text-w">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className="text-c3 lg:text-7xl underline"
      >
        Parcours ninja et trampoline parc
      </motion.h2>
      <section className="flex flex-col lg:flex-row items-start gap-4 pb-20">
        <motion.div
          initial={{
            x: "-100%",
            borderTopRightRadius: "100%",
            borderBottomRightRadius: "100%",
            width: "25%",
            height: "25%",
          }}
          animate={{
            x: "0%",
            borderTopRightRadius: "2%",
            borderBottomRightRadius: "2%",
            width: "100%",
            height: "100%",
            transition: { duration: 0.5 },
          }}
          className="lg:sticky flex-1 lg:rounded-none rounded-lg top-60 overflow-hidden grid place-content-center"
        >
          <motion.img
            initial={{ scale: 2 }}
            animate={{
              scale: 1,
              transition: { duration: 0.5 },
            }}
            src={trampo1}
            alt="pistes de bowling"
            className="w-full"
          />
        </motion.div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }}
          className="font-sans text-xl lg:pr-40 flex-1 font-light"
        >
          <span className="font-serif text-c3 text-3xl p-0 px-2 rounded-sm">
            Trampoline
          </span>
          <br />
          <br />
          Rebondir, sauter, maîtriser et muscler son corps, s’amuser en toute
          sécurité sur nos trampolines répondant aux normes de sécurité les plus
          élevées, c’est ce que vous propose West Parc.
          <br />
          <br />
          Selon la NASA, 10min de trampoline = 30min de course à pied à allure
          modérée, donc venez dépenser des calories tout en vous amusant et en
          famille ! <br />
          <br />
          <span className="text-c2">
            Nous vous invitons à réserver cette activité en nous contactant par
            téléphone ( 02 23 16 97 68 ) pour vous assurer le créneau horaire
            que vous souhaitez.
          </span>
          <br />
          <br />
          Le trampoline peut accueillir jusqu’à 20 personnes par session, une
          session dure 30min.
          <br />
          <br />
          A partir de 4 ans, sous la responsabilité des parents. <br />
          <br />
          Nous prenons soin de ne pas mélanger les plus jeunes avec des plus
          grands.
          <br />
          <br />
          Chaussettes antidérapantes obligatoires, vendues sur place.
          <br />
          <br />
          <span className="font-serif text-c3 text-3xl p-0 px-2 rounded-sm">
            Parcours ninja
          </span>
          <br />
          <br />
          Le parcours Ninja est une activité physique inspirée de l'émission de
          TF1 « Ninja Warrior ». Cette pratique inédite mêle l'escalade,
          l'accrobranche, le parcours d'obstacles, à l'image du parcours du
          combattant des militaires.
          <br />
          <br />
        </motion.p>
      </section>
      <video src={trampoVid} controls></video>
    </div>
  );
};

export default Trampo;
